import React, { useContext } from "react"
import { navigate } from "gatsby"
import callApi from "../../../services/api"
import { AccountContext } from "../../../context/account"
import "./dropdownbtn.css"

const LogOutButton = () => {
  const { dispatch, user } = useContext(AccountContext)
  const handleLogout = () => {
    callApi.auth.deauthenticate().then(() => {
      dispatch({ type: "userLoggedOut" })
      navigate("/login")
      return null
    })
  }

  return (
    <div
      className="dropdown items-center text-grey-6 bg-black-5 min-h-[25px] max-h-[25px] min-w-[84px] max-w-[84px] rounded border-[1px] border-solid border-black-5 px-3 py-1 ml-[10px] mr-[30px]"
      style={{
        display: "flex",
      }}
    >
      <button className="dropbtn flex">
        <span className="textBtn">ログアウト</span>
      </button>
      <div className="dropdown-content">
        <a href="#" onClick={handleLogout}>
          ログアウト
        </a>
        {user && !user?.store_id && (
          <a
            href="#"
            onClick={() => {
              navigate("/change-password-manual")
            }}
          >
            パスワードの更新
          </a>
        )}
      </div>
    </div>
  )
}

export default LogOutButton
