import request from "../customUtil/request"

export async function GetCollections() {
  return request("/admin/collections", {
    method: "GET",
  })
}
export async function getCollection() {
  return request(`/store/collection/list`, {
    method: "GET",
  })
}
export async function deleteAllImageOfStore(data) {
  return request(`/admin/product/images/delete?store_id=${data.storeId}`, {
    method: "POST",
  })
}
export async function UpdateCollections(data) {
  return request("/admin/update-collections", {
    method: "POST",
    data: data,
  })
}
export async function UpdateCollectionMedium(id, data) {
  return request(`/admin/collections/${id}`, {
    method: "POST",
    data: data,
  })
}
export async function setPickUp(data) {
  return request(`admin/product/pickup`, {
    method: "POST",
    data: data,
  })
}
export async function removePickUp(data) {
  return request(`admin/product/pickup/remove`, {
    method: "POST",
    data: data,
  })
}

export async function hidenVariant(data) {
  return request(`admin/variants/change-hidden`, {
    method: "POST",
    data: data,
  })
}
export async function UpdateCollectionBig(id, data) {
  return request(`/admin/collections/${id}`, {
    method: "POST",
    data: data,
  })
}

export async function SearchProduct(params) {
  return request("/admin/variants", {
    method: "GET",
    params,
  })
}

export async function getListProductForSPAdmin(param) {
  return request("/admin/super/list-product", {
    method: "GET",
    params: param,
  })
}

export async function duplicateProduct(id) {
  return request(`/admin/product/duplicate/${id}`, {
    method: "POST",
  })
}
