import request from "../customUtil/request"

export async function createAccount(data) {
  return request("admin/users", {
    method: "POST",
    data: data,
  })
}

export async function getOneAccount(id) {
  return request(`/admin/users/${id}`, {
    method: "GET",
  })
}

export async function updateAccount(id, data) {
  return request(`/admin/users/${id}`, {
    method: "POST",
    data,
  })
}

export async function searchAccount(params) {
  return request("admin/users/search", {
    method: "GET",
    params: params,
  })
}

export async function getAccount(params) {
  return request("/admin/users", {
    method: "GET",
    params,
  })
}

export async function deleteAccount(id) {
  return request(`/admin/users/${id}`, {
    method: "DELETE",
  })
}

export async function getRegion() {
  return request(`/store/regions`, {
    method: "GET",
  })
}
