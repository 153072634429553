export default {
  primary: "#3b77ff",
  secondary: "#79B28A",
  medusaGreen: "#454B54",
  nightBlue: "#0A3149",
  medusa: "#454B54",
  danger: "#FF7675",
  muted: "#E3E8EE",
  gray: "#a3acb9",
  lightest: "#fefefe",
  light: "#f0f0f0",
  dark: "#454545",
  placeholder: "#a3acb9",
  blue: "#5469d4",

  // new colors
  green: "#4BB543",
  yellow: "#FFD733",
  red: "#FF4133",
  link: "#5469D3",
  darkest: "#212121",
  lightest: "#FFFFFF",
  brand: {
    green: "#56FBB1",
    deepBlue: "#0A3149",
    coolGrey: "#EEF0F5",
  },
  decorative: {
    blue: "#8DEBFF",
    purple: "#ACB4FF",
    orange: "#FFBB95",
    red: "#FF9B9B",
  },
  "medusa-100": "#454B54",
  "medusa-80": "#89959C",
  "medusa-60": "#EEF0F5",
  "medusa-20": "#F7F7FA",
  "ui-100": "#89959C",
  "ui-75": "#D9DFE8",
  "ui-50": "#EEF0F5",
  "ui-25": "#F7F7FA",
}
