exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-a-js": () => import("./../../../src/pages/a.js" /* webpackChunkName: "component---src-pages-a-js" */),
  "component---src-pages-change-password-manual-index-js": () => import("./../../../src/pages/change-password-manual/index.js" /* webpackChunkName: "component---src-pages-change-password-manual-index-js" */),
  "component---src-pages-commodity-commodity-management-js": () => import("./../../../src/pages/commodity/commodity-management.js" /* webpackChunkName: "component---src-pages-commodity-commodity-management-js" */),
  "component---src-pages-commodity-index-js": () => import("./../../../src/pages/commodity/index.js" /* webpackChunkName: "component---src-pages-commodity-index-js" */),
  "component---src-pages-commodity-list-products-index-js": () => import("./../../../src/pages/commodity/list-products/index.js" /* webpackChunkName: "component---src-pages-commodity-list-products-index-js" */),
  "component---src-pages-commodity-new-product-index-js": () => import("./../../../src/pages/commodity/new-product/index.js" /* webpackChunkName: "component---src-pages-commodity-new-product-index-js" */),
  "component---src-pages-commodity-new-product-new-product-page-js": () => import("./../../../src/pages/commodity/new-product/NewProductPage.js" /* webpackChunkName: "component---src-pages-commodity-new-product-new-product-page-js" */),
  "component---src-pages-commodity-update-product-index-js": () => import("./../../../src/pages/commodity/update-product/index.js" /* webpackChunkName: "component---src-pages-commodity-update-product-index-js" */),
  "component---src-pages-commodity-update-product-update-product-page-js": () => import("./../../../src/pages/commodity/update-product/UpdateProductPage.js" /* webpackChunkName: "component---src-pages-commodity-update-product-update-product-page-js" */),
  "component---src-pages-company-info-company-info-js": () => import("./../../../src/pages/company-info/company-info.js" /* webpackChunkName: "component---src-pages-company-info-company-info-js" */),
  "component---src-pages-company-info-index-js": () => import("./../../../src/pages/company-info/index.js" /* webpackChunkName: "component---src-pages-company-info-index-js" */),
  "component---src-pages-company-info-register-handle-company-info-[id]-js": () => import("./../../../src/pages/company-info/register/handleCompanyInfo/[id].js" /* webpackChunkName: "component---src-pages-company-info-register-handle-company-info-[id]-js" */),
  "component---src-pages-company-info-register-index-js": () => import("./../../../src/pages/company-info/register/index.js" /* webpackChunkName: "component---src-pages-company-info-register-index-js" */),
  "component---src-pages-company-info-register-reg-confirm-js": () => import("./../../../src/pages/company-info/register/RegConfirm.js" /* webpackChunkName: "component---src-pages-company-info-register-reg-confirm-js" */),
  "component---src-pages-company-info-register-register-form-js": () => import("./../../../src/pages/company-info/register/register-form.js" /* webpackChunkName: "component---src-pages-company-info-register-register-form-js" */),
  "component---src-pages-company-info-register-thank-you-index-js": () => import("./../../../src/pages/company-info/register/thank-you/index.js" /* webpackChunkName: "component---src-pages-company-info-register-thank-you-index-js" */),
  "component---src-pages-company-info-register-thank-you-thank-you-js": () => import("./../../../src/pages/company-info/register/thank-you/ThankYou.js" /* webpackChunkName: "component---src-pages-company-info-register-thank-you-thank-you-js" */),
  "component---src-pages-forgot-password-tsx": () => import("./../../../src/pages/forgot-password.tsx" /* webpackChunkName: "component---src-pages-forgot-password-tsx" */),
  "component---src-pages-home-home-js": () => import("./../../../src/pages/home/Home.js" /* webpackChunkName: "component---src-pages-home-home-js" */),
  "component---src-pages-home-index-js": () => import("./../../../src/pages/home/index.js" /* webpackChunkName: "component---src-pages-home-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-inquiry-management-detail-order-[id]-jsx": () => import("./../../../src/pages/inquiry-management/detail-order/[id].jsx" /* webpackChunkName: "component---src-pages-inquiry-management-detail-order-[id]-jsx" */),
  "component---src-pages-inquiry-management-index-jsx": () => import("./../../../src/pages/inquiry-management/index.jsx" /* webpackChunkName: "component---src-pages-inquiry-management-index-jsx" */),
  "component---src-pages-inquiry-management-setting-management-index-js": () => import("./../../../src/pages/inquiry-management/setting-management/index.js" /* webpackChunkName: "component---src-pages-inquiry-management-setting-management-index-js" */),
  "component---src-pages-invite-js": () => import("./../../../src/pages/invite.js" /* webpackChunkName: "component---src-pages-invite-js" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-manage-js": () => import("./../../../src/pages/manage.js" /* webpackChunkName: "component---src-pages-manage-js" */),
  "component---src-pages-news-detail-[id]-js": () => import("./../../../src/pages/news/detail/[id].js" /* webpackChunkName: "component---src-pages-news-detail-[id]-js" */),
  "component---src-pages-news-detail-news-[id]-js": () => import("./../../../src/pages/news/detail-news/[id].js" /* webpackChunkName: "component---src-pages-news-detail-news-[id]-js" */),
  "component---src-pages-news-index-jsx": () => import("./../../../src/pages/news/index.jsx" /* webpackChunkName: "component---src-pages-news-index-jsx" */),
  "component---src-pages-news-message-index-js": () => import("./../../../src/pages/news-message/index.js" /* webpackChunkName: "component---src-pages-news-message-index-js" */),
  "component---src-pages-news-message-new-message-js": () => import("./../../../src/pages/news-message/NewMessage.js" /* webpackChunkName: "component---src-pages-news-message-new-message-js" */),
  "component---src-pages-on-the-go-index-js": () => import("./../../../src/pages/on-the-go/index.js" /* webpackChunkName: "component---src-pages-on-the-go-index-js" */),
  "component---src-pages-order-management-detail-index-jsx": () => import("./../../../src/pages/order-management/detail/index.jsx" /* webpackChunkName: "component---src-pages-order-management-detail-index-jsx" */),
  "component---src-pages-order-management-index-js": () => import("./../../../src/pages/order-management/index.js" /* webpackChunkName: "component---src-pages-order-management-index-js" */),
  "component---src-pages-payment-management-index-jsx": () => import("./../../../src/pages/payment-management/index.jsx" /* webpackChunkName: "component---src-pages-payment-management-index-jsx" */),
  "component---src-pages-post-management-index-js": () => import("./../../../src/pages/post-management/index.js" /* webpackChunkName: "component---src-pages-post-management-index-js" */),
  "component---src-pages-reset-password-js": () => import("./../../../src/pages/reset-password.js" /* webpackChunkName: "component---src-pages-reset-password-js" */),
  "component---src-pages-review-management-gocoming-index-jsx": () => import("./../../../src/pages/review-management-gocoming/index.jsx" /* webpackChunkName: "component---src-pages-review-management-gocoming-index-jsx" */),
  "component---src-pages-sales-management-detail-index-jsx": () => import("./../../../src/pages/sales-management/detail/index.jsx" /* webpackChunkName: "component---src-pages-sales-management-detail-index-jsx" */),
  "component---src-pages-sales-management-index-js": () => import("./../../../src/pages/sales-management/index.js" /* webpackChunkName: "component---src-pages-sales-management-index-js" */),
  "component---src-pages-sign-up-tsx": () => import("./../../../src/pages/sign-up.tsx" /* webpackChunkName: "component---src-pages-sign-up-tsx" */),
  "component---src-pages-super-ad-news-create-news-index-js": () => import("./../../../src/pages/super-ad-news/create-news/index.js" /* webpackChunkName: "component---src-pages-super-ad-news-create-news-index-js" */),
  "component---src-pages-super-ad-news-detail-news-[id]-js": () => import("./../../../src/pages/super-ad-news/detail-news/[id].js" /* webpackChunkName: "component---src-pages-super-ad-news-detail-news-[id]-js" */),
  "component---src-pages-super-ad-news-index-js": () => import("./../../../src/pages/super-ad-news/index.js" /* webpackChunkName: "component---src-pages-super-ad-news-index-js" */),
  "component---src-pages-super-ad-site-management-index-js": () => import("./../../../src/pages/super-ad-site-management/index.js" /* webpackChunkName: "component---src-pages-super-ad-site-management-index-js" */),
  "component---src-pages-super-ad-tax-management-for-product-index-js": () => import("./../../../src/pages/super-ad-tax-management/for-product/index.js" /* webpackChunkName: "component---src-pages-super-ad-tax-management-for-product-index-js" */),
  "component---src-pages-super-ad-tax-management-index-js": () => import("./../../../src/pages/super-ad-tax-management/index.js" /* webpackChunkName: "component---src-pages-super-ad-tax-management-index-js" */),
  "component---src-pages-super-chat-chat-index-jsx": () => import("./../../../src/pages/super-chat/chat/index.jsx" /* webpackChunkName: "component---src-pages-super-chat-chat-index-jsx" */),
  "component---src-pages-super-chat-index-js": () => import("./../../../src/pages/super-chat/index.js" /* webpackChunkName: "component---src-pages-super-chat-index-js" */),
  "component---src-pages-super-list-company-detail-index-jsx": () => import("./../../../src/pages/super-list-company/detail/index.jsx" /* webpackChunkName: "component---src-pages-super-list-company-detail-index-jsx" */),
  "component---src-pages-super-list-company-index-jsx": () => import("./../../../src/pages/super-list-company/index.jsx" /* webpackChunkName: "component---src-pages-super-list-company-index-jsx" */),
  "component---src-pages-super-listed-image-management-index-js": () => import("./../../../src/pages/super-listed-image-management/index.js" /* webpackChunkName: "component---src-pages-super-listed-image-management-index-js" */),
  "component---src-pages-super-listed-product-management-detail-index-jsx": () => import("./../../../src/pages/super-listed-product-management/detail/index.jsx" /* webpackChunkName: "component---src-pages-super-listed-product-management-detail-index-jsx" */),
  "component---src-pages-super-listed-product-management-index-jsx": () => import("./../../../src/pages/super-listed-product-management/index.jsx" /* webpackChunkName: "component---src-pages-super-listed-product-management-index-jsx" */),
  "component---src-pages-super-overview-index-js": () => import("./../../../src/pages/super-overview/index.js" /* webpackChunkName: "component---src-pages-super-overview-index-js" */),
  "component---src-pages-super-sales-commission-setting-company-index-jsx": () => import("./../../../src/pages/super-sales-commission-setting-company/index.jsx" /* webpackChunkName: "component---src-pages-super-sales-commission-setting-company-index-jsx" */),
  "component---src-pages-super-sales-commission-setting-company-setting-for-product-index-jsx": () => import("./../../../src/pages/super-sales-commission-setting-company/setting/for-product/index.jsx" /* webpackChunkName: "component---src-pages-super-sales-commission-setting-company-setting-for-product-index-jsx" */),
  "component---src-pages-super-sales-commission-setting-company-setting-index-jsx": () => import("./../../../src/pages/super-sales-commission-setting-company/setting/index.jsx" /* webpackChunkName: "component---src-pages-super-sales-commission-setting-company-setting-index-jsx" */),
  "component---src-pages-withdraw-money-breadcrumb-breadcrumb-jsx": () => import("./../../../src/pages/withdraw-money/breadcrumb/breadcrumb.jsx" /* webpackChunkName: "component---src-pages-withdraw-money-breadcrumb-breadcrumb-jsx" */),
  "component---src-pages-withdraw-money-index-delete-account-jsx": () => import("./../../../src/pages/withdraw-money/IndexDeleteAccount.jsx" /* webpackChunkName: "component---src-pages-withdraw-money-index-delete-account-jsx" */),
  "component---src-pages-withdraw-money-index-js": () => import("./../../../src/pages/withdraw-money/index.js" /* webpackChunkName: "component---src-pages-withdraw-money-index-js" */),
  "component---src-pages-withdraw-money-steps-step-1-js": () => import("./../../../src/pages/withdraw-money/steps/Step1.js" /* webpackChunkName: "component---src-pages-withdraw-money-steps-step-1-js" */),
  "component---src-pages-withdraw-money-steps-step-2-js": () => import("./../../../src/pages/withdraw-money/steps/Step2.js" /* webpackChunkName: "component---src-pages-withdraw-money-steps-step-2-js" */),
  "component---src-pages-withdraw-money-steps-step-3-js": () => import("./../../../src/pages/withdraw-money/steps/Step3.js" /* webpackChunkName: "component---src-pages-withdraw-money-steps-step-3-js" */),
  "component---src-pages-withdraw-money-steps-step-4-js": () => import("./../../../src/pages/withdraw-money/steps/Step4.js" /* webpackChunkName: "component---src-pages-withdraw-money-steps-step-4-js" */),
  "component---src-pages-withdraw-money-with-draw-money-js": () => import("./../../../src/pages/withdraw-money/WithDrawMoney.js" /* webpackChunkName: "component---src-pages-withdraw-money-with-draw-money-js" */)
}

